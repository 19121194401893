* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }
  
  html {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
      'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
      sans-serif;
    margin: 0;
    padding: 0;
    text-decoration-skip-ink: 'auto';
  }
  
  :root {
    --gray-900: #111114;
    --gray-800: #222224;
    --gray-700: #444444;
    --gray-600: #666664;
    --gray-500: #888884;
    --gray-400: #aaaaa4;
    --gray-300: #ccccc4;
    --gray-200: #e2e2e4;
    --gray-100: #f9f9f9;
    --white: white;
    --blue: #4299e1;
    --red: #f56565;
    --text-decoration-color: var(--gray-400);
    --text-color: var(--gray-800);
    --focus-ring-color: var(--blue);
    --border-radius: 4px;
  }
  
  ::selection,
  ::-moz-selection {
    background: var(--focus-ring-color); /* WebKit/Blink Browsers */
  }
  body {
    padding: 0;
    margin: 0;
    background-color: white;
    font-weight: 400;
    line-height: 1.563;
    color: var(--text-color);
    caret-color: var(--text-color);
  }
  
  /* @media (prefers-color-scheme: dark) {
    body {
      color: white;
      background-color: var(--gray-900);
      caret-color: white;
    }
  } */
  
  /* Typography
  ––––––––––––––––––––––––––––––––– */
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    margin-top: 1em;
    font-weight: bold;
  }
  
  h1 {
    font-size: 3.052rem;
    letter-spacing: -0.04em;
    line-height: 1;
  }
  
  h2 {
    font-size: 2.441rem;
    letter-spacing: -0.04em;
    line-height: 1.2;
  }
  
  h3 {
    font-size: 1.953rem;
    letter-spacing: -0.03em;
    line-height: 1.2;
  }
  
  h4 {
    font-size: 1.563rem;
    line-height: 1.3;
  }
  
  h5 {
    font-size: 1.25rem;
    line-height: 1.4;
  }
  
  h6 {
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.5;
  }
  
  p {
    margin-bottom: 1.563rem;
  }
  
  p > *:last-child {
    margin-bottom: 0;
  }
  
  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: var(--text-decoration-color);
    -webkit-text-decoration-color: var(--text-decoration-color);
    border-radius: 5px;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  
  @media (hover: hover) {
    a:hover {
      text-decoration-color: var(--gray-900);
    }
  }
  
  /* @media (hover: hover) and (prefers-color-scheme: dark) {
    a:hover {
      text-decoration-color: white;
    }
  } */
  
  /* @media (prefers-color-scheme: dark) {
    a {
      text-decoration-color: var(--gray-600);
      -webkit-text-decoration-color: var(--gray-600);
    }
  } */
  
  a:focus-visible {
    box-shadow: 0 0 0 2px var(--focus-ring-color);
    outline: none;
  }
  
  small {
    font-size: 0.888rem;
  }
  
  hr {
    border: 1px solid var(--gray-700);
    margin: 3.052rem 0;
  }
  
  /* Form
  ––––––––––––––––––––––––––––––––– */
  label {
    font-weight: bold;
    display: flex;
  }
  
  input[type='email'],
  input[type='text'],
  input[type='number'] {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--gray-800);
    border-radius: var(--border-radius);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='email'],
    input[type='text'],
    input[type='number'] {
      background-color: var(--gray-700);
      border-color: var(--gray-700);
      color: white;
    }
  } */
  
  input[type='email']:hover,
  input[type='text']:hover,
  input[type='number']:hover,
  textarea:hover {
    border: 2px solid var(--gray-300);
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='email']:hover,
    input[type='text']:hover,
    input[type='number']:hover,
    textarea:hover {
      border: 2px solid var(--gray-600);
    }
  } */
  
  select {
    -webkit-appearance: none;
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    border-radius: var(--border-radius);
    color: var(--gray-700);
    height: 2.5rem;
    background-color: var(--gray-100);
    background-image: url('data:image/svg+xml;utf8,<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center right 0.5rem;
  }
  
  /* @media (prefers-color-scheme: dark) {
    select {
      background-color: var(--gray-700);
      background-image: url('data:image/svg+xml;utf8,<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
      border-color: var(--gray-700);
      color: white;
    }
  } */

  div[class='test'] {
    -webkit-appearance: none;
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    border-radius: var(--border-radius);
    color: var(--gray-700);
    height: 2.5rem;
    background-color: var(--gray-100);
    background-image: url('data:image/svg+xml;utf8,<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center right 0.5rem;
  }
  
  /* @media (prefers-color-scheme: dark) {
    div[class='test'] {
      background-color: var(--gray-700);
      background-image: url('data:image/svg+xml;utf8,<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
      border-color: var(--gray-700);
      color: white;
    }
  } */
  
  select[multiple] {
    height: auto;
    background-image: none;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: initial;
  }

  /* @media (prefers-color-scheme: dark) {
    select[multiple] {
      background-color: var(--gray-700);
      border-color: var(--gray-700);
      color: white;
    }
  } */
  
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    color: var(--gray-700);
    border-radius: var(--border-radius);
    resize: vertical;
    background-color: var(--gray-100);
    box-sizing: border-box;
    padding: 0.65rem 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
      'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
      sans-serif;
  }
  
  /* @media (prefers-color-scheme: dark) {
    textarea {
      background-color: var(--gray-700);
      border-color: var(--gray-700);
      color: white;
    }
  } */
  
  input:focus,
  select:focus,
  textarea:focus,
  input:hover:focus,
  select:hover:focus,
  textarea:hover:focus {
    outline: none;
    border: 2px solid var(--focus-ring-color);
  }
  
  input:invalid,
  select:invalid,
  textarea:invalid {
    border: 2px solid #ff7d87;
    box-shadow: none;
  }
  
  input[type='checkbox'] {
    display: inline-block;
    height: 1rem;
    font-size: 1rem;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid var(--gray-300);
    width: 1rem;
    background-color: white;
    align-self: center;
    margin-right: 0.5rem;
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='checkbox'] {
      background-color: var(--gray-900);
      border-color: white;
    }
  } */
  
  input[type='checkbox']:hover {
    cursor: pointer;
    border: 2px solid var(--gray-300);
  }
  
  input[type='checkbox']:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="white" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
    background-size: contain;
    background-color: var(--gray-700);
    border: 2px solid var(--gray-700);
  }
  
  input[type='checkbox']:focus-visible,
  input[type='checkbox']:checked:focus-visible {
    border-color: var(--focus-ring-color);
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='checkbox']:checked {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="%23343434" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
      background-color: white;
      border: 2px solid white;
    }
  } */
  
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    border: 2px solid var(--gray-300);
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    align-self: center;
    justify-content: center;
    position: relative;
    display: flex;
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='radio'] {
      border: 2px solid var(--gray-200);
    }
  } */
  
  @media (hover: hover) {
    input[type='radio']:hover {
      cursor: pointer;
    }
  }
  
  input[type='radio']:checked {
    border: 2px solid var(--gray-700);
  }
  
  input[type='radio']:focus-visible,
  input[type='radio']:checked:focus-visible {
    border-color: var(--focus-ring-color);
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='radio']:checked {
      border: 2px solid white;
    }
  } */
  
  input[type='radio']:checked::before {
    content: '';
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: var(--gray-700);
    align-self: center;
    border-radius: 50%;
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='radio']:checked::before {
      background-color: white;
    }
  } */
  
  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button[type='submit'],
  button[type='reset'] {
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--gray-700);
    border: 2px solid var(--gray-700);
    color: white;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    line-height: initial;
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
      transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  button:hover {
    cursor: pointer;
  }
  input[type='submit']:hover,
  input[type='button']:hover,
  button[type='submit']:hover {
    background: var(--gray-900);
  }
  
  /* @media (prefers-color-scheme: dark) {
    input[type='submit'],
    input[type='button'],
    button[type='submit'] {
      background-color: white;
      border: 2px solid white;
      color: var(--gray-900);
    }
  
    input[type='submit']:hover,
    input[type='button']:hover,
    button[type='submit']:hover {
      background-color: var(--gray-300);
      border: 2px solid var(--gray-300);
      cursor: pointer;
    }
  } */
  
  button:focus-visible,
  input[type='submit']:focus-visible,
  input[type='reset']:focus-visible,
  input[type='button']:focus-visible {
    border-color: var(--focus-ring-color);
    outline: none;
  }
  
  /* Tables
  ––––––––––––––––––––––––––––––––– */
  table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 1.563rem;
    font-variant-numeric: tabular-nums;
  }
  
  th,
  td {
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin: 0;
  }
  
  th {
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid var(--gray-300);
    color: var(--gray-300);
  }
  
  /* @media (prefers-color-scheme: dark) {
    th {
      border-color: var(--gray-700);
      color: var(--gray-700);
    }
  }
   */
  td {
    border-bottom: 2px solid var(--gray-300);
  }
  
  /* @media (prefers-color-scheme: dark) {
    td {
      border-color: var(--gray-700);
    }
  } */
  
  /* Code
  ––––––––––––––––––––––––––––––––– */
  code {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
    font-size: 0.8rem;
    white-space: nowrap;
    background: var(--gray-100);
    padding: 0 0.328rem;
    display: inline-block;
    vertical-align: middle;
    border-radius: var(--border-radius);
  }
  
  /* @media (prefers-color-scheme: dark) {
    code {
      background-color: var(--gray-700);
    }
  } */
  
  p > code {
    white-space: normal;
  }
  
  pre > code {
    line-height: 1.563em;
    display: block;
    padding: 1rem;
    white-space: pre;
    margin-bottom: 1.563rem;
    overflow: scroll;
  }
  
  /* Forces a new-line at the end of a code block for layout purposes. */
  pre > code::after {
    content: ' ';
  }
  
  /* Blockquote
  ––––––––––––––––––––––––––––––––– */
  blockquote {
    border-left: 0.25rem solid var(--gray-100);
    padding: 0 1rem;
    margin-bottom: 1.563rem;
  }
  
  /* @media (prefers-color-scheme: dark) {
    blockquote {
      border-left: 0.25rem solid var(--gray-700);
    }
  } */
  
  /* List
  ––––––––––––––––––––––––––––––––– */
  ul {
    margin: 0;
    padding: 0 1px;
    list-style: disc outside;
    font-variant-numeric: tabular-nums;
  }
  
  ol {
    list-style: decimal outside;
  }
  
  ol,
  ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 1.563rem;
  }
  
  li {
    list-style-position: inside;
  }
  
  /* Keyboard
  ––––––––––––––––––––––––––––––––– */
  kbd {
    display: inline-block;
    padding: 0 0.328rem;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
    font-size: 11px;
    color: var(--gray-700);
    vertical-align: middle;
    background-color: var(--gray-100);
    border: solid 1px var(--gray-300);
    border-bottom: solid 2px var(--gray-500);
    border-radius: 5px;
  }
  
  /* Abbreviation
  ––––––––––––––––––––––––––––––––– */
  abbr {
    text-decoration: none;
    border-bottom: 2px dashed var(--gray-700);
  }
  
  @media (hover: hover) {
    abbr:hover {
      cursor: help;
    }
  }
  
  .input-feedback {
    color: red;
    margin-top: 0.25rem;
  }
  
  .error {
    color: red;
  }
  
  .success {
    color: green;
  }
  
  .form-control {
    margin-top: 1rem;
  }
  
  .valid input[type='email'],
  .valid input[type='text'],
  .valid input[type='number'] {
    border-color: green;
  }
  
  .valid .feedback {
    color: green;
  }
  
  .invalid input[type='email'],
  .invalid input[type='text'],
  .invalid input[type='number'] {
    border-color: red;
  }
  
  .invalid .feedback {
    color: red;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  
  button + button {
    margin-left: 0.5rem;
  }
  
  button.outline,
  button[type='reset'],
  input[type='reset'] {
    background-color: var(--gray-200);
    border: 2px solid var(--gray-200);
    color: var(--gray-900);
  }
  button.outline:hover,
  button[type='reset']:hover,
  input[type='reset']:hover {
    background-color: var(--gray-300);
    border: 2px solid var(--gray-300);
  }
  
  @media (prefers-color-scheme: dark) {
    button.outline,
    button[type='reset'],
    input[type='reset'] {
      background-color: var(--gray-800);
      border: 2px solid var(--gray-700);
      color: var(--gray-100);
    }
  
    button.outline:hover,
    button[type='reset']:hover,
    input[type='reset']:hover {
      background-color: var(--gray-900);
      border: 2px solid var(--gray-700);
      color: var(--gray-200);
      cursor: pointer;
    }
  }
  
  input[type='email'],
  input[type='text'],
  input[type='number'],
  textarea,
  select {
    width: 100%;
  }
  
  label.checkbox {
    font-weight: normal;
  }
  
  button[type='submit'],
  button[type='reset'] {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  
  .app {
    margin: 0 auto;
    max-width: 500px;
    padding: 1rem;
  }
  
  .example {
    padding: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: var(--border-radius);
  }
  
  .example label:first-child,
  .example .form-control:first-child {
    margin-top: 0;
  }
  
  @media (prefers-color-scheme: dark) {
    .example {
      border: 1px solid var(--gray-700);
    }
  }
  
  .text-xs {
    font-size: 0.75rem;
  }
  .text-sm {
    font-size: 0.875rem;
  }
  .text-base {
    font-size: 1rem;
  }
  .text-lg {
    font-size: 1.125rem;
  }
  .text-xl {
    font-size: 1.25rem;
  }
  .text-2xl {
    font-size: 1.5rem;
  }
  .text-3xl {
    font-size: 1.875rem;
  }
  .text-4xl {
    font-size: 2.25rem;
  }
  .text-5xl {
    font-size: 3rem;
  }
  .text-6xl {
    font-size: 4rem;
  }
  
  .flex {
    display: flex;
  }
  
  .space-between {
    justify-content: space-between;
  }
  
  .items-center {
    align-items: center;
  }
  
  .mt-1 {
    margin-top: 1rem;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  @keyframes spinner-border {
    to { transform: rotate(360deg); }
  }
  
  /* img {
    position: relative;
    } */
    
 img {
    position: relative; 
    left:-12px;
    flex-shrink: 3;
    }
  
